import { Title } from '@solidjs/meta';
import {
	Button,
	Errors,
	Field,
	FieldErrors,
	Heading,
	Input,
	Label,
	Option,
	Select,
	TextField,
	TextLink,
} from '@troon/ui';
import { Match, Switch } from 'solid-js';
import { NoHydration } from 'solid-js/web';
import { Formspree, formspreeAction } from '@troon/forms';
import { IconCircleCheck } from '@troon/icons';
import { useSubmission } from '@solidjs/router';
import { Content } from '../../../components/content';
import { Grid, GridHalf } from '../../../components/layouts/grid';
import { getConfigValue } from '../../../modules/config';
import { useUser } from '../../../providers/user';

export default function ManagementServicesPage() {
	const user = useUser();
	const submission = useSubmission(action);

	return (
		<>
			<Title>Golf club management services | Troon</Title>

			<Content class="flex flex-col gap-8">
				<Heading as="h1">Golf club management services</Heading>

				<Grid>
					<NoHydration>
						<GridHalf class="flex flex-col gap-8">
							<Heading as="h2">About us</Heading>
							<p>
								Troon® started as one facility in 1990 and has since grown to become the world’s largest professional
								club management company. Today, we provide services to 900+ locations and manage various amenities, such
								as racquet sports, aquatics, fitness, food & beverage, lodging, homeowners associations, and more.
							</p>
						</GridHalf>
					</NoHydration>

					<GridHalf class="flex flex-col gap-8">
						<Heading as="h2">Let us hear from you</Heading>
						<p>
							To speak with a member of our Business Development team, please call{' '}
							<TextLink href="tel:4804770505">480.477.0505</TextLink> or complete the following form:
						</p>

						<p class="italic text-neutral-700">
							For Troon Card/Rewards questions, please <TextLink href="/about/contact">contact support</TextLink>.
						</p>

						<Switch>
							<Match when={submission.result}>
								<div class="container mx-auto flex max-w-xl flex-col items-center gap-6 text-center">
									<IconCircleCheck class="text-8xl text-brand" />
									<Heading as="h1" size="h2">
										Message received!
									</Heading>
									<p class="text-lg">
										Thanks for contacting us! Our team is reviewing your request and we will get in touch with you
										shortly.
									</p>
								</div>
							</Match>
							<Match when>
								<Formspree action={action}>
									<div class="flex w-full flex-wrap gap-6">
										<TextField name="First name" required class="grow">
											<Label>First name</Label>
											<Input autocomplete="given-name" value={user()?.me.firstName} />
										</TextField>

										<TextField name="Last name" required class="grow">
											<Label>Last name</Label>
											<Input autocomplete="family-name" value={user()?.me.lastName} />
										</TextField>
									</div>

									<TextField name="email" required>
										<Label>Email address</Label>
										<Input autocomplete="email" inputMode="email" />
									</TextField>

									<TextField name="Phone number" required>
										<Label>Phone number</Label>
										<Input autocomplete="tel" inputMode="tel" />
									</TextField>

									<Field name="Category of interest" required>
										<Label>Category of interest</Label>
										<Select>
											<Option disabled selected class="hidden" />
											<Option>Private Club</Option>
											<Option>Daily Fee/Resort</Option>
											<Option>Municipal</Option>
											<Option>Community Association</Option>
											<Option>Advisory Services</Option>
											<Option>Troon Partners Network</Option>
											<Option>Other</Option>
										</Select>
										<FieldErrors />
									</Field>

									<Field name="Your role/position" required>
										<Label>Your role/position</Label>
										<Select>
											<Option disabled selected class="hidden" />
											<Option>Owner</Option>
											<Option>Owner Representative</Option>
											<Option>Club Board Member</Option>
											<Option>General Manager</Option>
											<Option>Mayor</Option>
											<Option>City Manager</Option>
											<Option>Chief Financial Officer</Option>
											<Option>Parks and Recreation Director</Option>
											<Option>Other</Option>
										</Select>
										<FieldErrors />
									</Field>

									<TextField name="Message" required>
										<Label>Message</Label>
										<Input multiline />
									</TextField>

									<Errors />
									<Button type="submit" class="w-fit">
										Submit
									</Button>
								</Formspree>
							</Match>
						</Switch>
					</GridHalf>
				</Grid>
			</Content>
		</>
	);
}

const action = formspreeAction(getConfigValue('FORMSPREE_PROJECT_ID'), 'management-services');
